
import { Component, Vue } from "vue-property-decorator";
import { CalendarEvent } from "vuetify";
import moment from "moment-timezone";

@Component
export default class SchedulePage extends Vue {
  typeToLabel = {
    month: this.$t("EREPORTER.SCHEDULE.MONTH_VIEW"),
    week: this.$t("EREPORTER.SCHEDULE.WEEK_VIEW"),
    day: this.$t("EREPORTER.SCHEDULE.DAY_VIEW"),
    "4day": this.$t("EREPORTER.SCHEDULE.FOUR_DAY_VIEW")
  };

  weekday = [1, 2, 3, 4, 5, 6, 0];
  selectedEvent: CalendarEvent = {};
  selectedElement = null;
  selectedOpen = false;

  events: CalendarEvent[] = [];
  holidays: CalendarEvent[] = [];
  vacationRequests: CalendarEvent[] = [];

  scheduleData = [];
  vacationRequestsData = [];

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get focus(): string {
    return this.$store.getters["schedule/focus"];
  }

  set focus(value) {
    this.$store.commit("schedule/setFocus", value);
  }

  get type(): string {
    return this.$store.getters["schedule/type"];
  }

  set type(value) {
    this.$store.commit("schedule/setType", value);
  }

  get isBeforeToday() {
    return moment(this.focus).isBefore(moment().startOf("day"));
  }

  mounted() {
    moment.tz.setDefault();
    // (this.$refs.calendar as CalendarEvent).checkChange();
  }

  setEvents(): void {
    const events: CalendarEvent[] = [];

    Object.keys(this.scheduleData).forEach(event => {
      if (this.scheduleData[event].length) {
        this.scheduleData[event].forEach(task => {
          events.push({
            id: task.id,
            isBlock: task.order == null,
            name: task.order
              ? `${task.time_from}—${task.time_to} ${task.order.city}`
              : task.duration < 24
              ? `${task.time_from}—${task.time_to} ${task.reason.name}`
              : task.reason.name,
            start: moment(`${task.date_from} ${task.time_from}`).toDate(),
            end:
              task.duration < 24
                ? moment(`${task.date_from} ${task.time_from}`)
                    .add(task.duration, "hours")
                    .toDate()
                : null,
            color: task.color,
            timed: this.type == "month" ? false : task.duration < 24,
            details: task.order
              ? `Order #${task.order.order_number}`
              : `${task.time_from}—${task.time_to}`
          });
        });
      }
    });

    this.events = events;
  }

  setVacationRequests(): void {
    const requests: CalendarEvent[] = [];

    this.vacationRequestsData.forEach((event: Record<string, any>) => {
      requests.push({
        id: event.id,
        name: this.$t("EREPORTER.VACATION.REQUEST"),
        start: moment(event.date_from).toDate(),
        end: moment(event.date_to).toDate(),
        isVacationRequest: true,
        color: event.status.color
      });
    });

    this.vacationRequests = requests;
  }

  setHolidays(): void {
    const holidays: CalendarEvent[] = [];

    this.holidays.forEach((event: Record<string, any>) => {
      holidays.push({
        type: "HOLIDAY",
        id: event.id,
        name: event.name,
        is_all_regions: event.is_all_regions,
        details: event.regions,
        start: moment(event.start).toDate(),
        end: moment(event.end).toDate(),
        color: "#ff0000"
      });
    });

    this.holidays = holidays;
  }

  viewDay({ date }) {
    this.focus = date;
    this.type = "day";
  }

  getEventColor(event) {
    return event.color;
  }

  setToday() {
    this.focus = "";
  }

  prev(): void {
    (this.$refs.calendar as CalendarEvent).prev();
  }

  next(): void {
    (this.$refs.calendar as CalendarEvent).next();
  }

  showEvent({ nativeEvent, event }) {
    if (event.isVacationRequest) {
      this.$router.push({
        name: "vacation.requests.edit",
        params: { id: event.id }
      });
    }

    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => {
        this.selectedOpen = true;
      }, 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  updateRange({ start, end }) {
    this.fetchEvents(start.date, end.date);
    this.fetchVacationRequests(start.date, end.date);
    this.fetchHolidaysInRange(start.date, end.date);
  }

  fetchEvents(start: Date, end: Date) {
    this.$store.dispatch("schedule/fetch", { start, end }).then(({ data }) => {
      this.scheduleData = data.data;
      this.setEvents();
    });
  }

  fetchVacationRequests(start: Date, end: Date) {
    this.$store
      .dispatch("vacation/fetchInRange", { start, end })
      .then(({ data }) => {
        this.vacationRequestsData = data.data;
        this.setVacationRequests();
      });
  }

  fetchHolidaysInRange(start: Date, end: Date) {
    this.$store
      .dispatch("vacation/fetchHolidaysInRange", { start, end })
      .then(({ data }) => {
        this.holidays = data.data;
        this.setHolidays();
      });
  }

  setBlockDate() {
    return this.type == "day" && this.focus != ""
      ? moment(this.focus).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
  }
}
